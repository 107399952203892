/* eslint-disable */

import React from "react";

import { useTranslation } from "react-i18next";
import striptags from 'striptags';

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import { useCookies } from "react-cookie";
import "../less/my-credits.css";
import "../less/quiz.css";
import "../less/test.css";
import { tHydrate } from "../Utils/HydrateT";

import {
  IconPrimarySchool1stGrade,
  IconPrimarySchool2ndGrade,
  IconPrimarySchool3rdGrade,
  IconPrimarySchool4thGrade,
  IconPrimarySchool5thGrade,
  IconPrimarySchool6thGrade,
  IconLowerSecondarySchool1stGrade,
  IconLowerSecondarySchool2ndGrade,
  IconLowerSecondarySchool3rdGrade,
  IconUpperSecondarySchool1stGrade,
  IconUpperSecondarySchool2ndGrade,
  IconUpperSecondarySchool3rdGrade
} from './IconDirectories';

const APPETIZER_COMMON_COLOR = '#132b5d';

const renderAppetizerNumber = (numbersInAppetizer) => {
  return <div style={{
    display: 'inline-block',
    fontFamily: 'Arial',
    color: APPETIZER_COMMON_COLOR
  }}>{numbersInAppetizer}</div>
}

function ContentComponent(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  const token = cookies["access_token"]; //getToken();

  const user = cookies["user"]; //getUser();

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;
  const PAGE_LIMIT = 10;

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;

  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  const stripHtmlWithParagraphs = (htmlString) => {

    const decodeHTMLEntities = (text) => {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = text;
      return textarea.value;
    };

    // Remove all HTML tags except <p>
    let processedText = striptags(htmlString, ['p']);

    // Replace <p> tags with newlines
    processedText = processedText
      .replace(/<p[^>]*>/g, '')
      .replace(/<\/p>/g, '\n')
      .trim();

    // Decode HTML entities
    processedText = decodeHTMLEntities(processedText);

    return processedText;
  };

  const goToContentDetailsPage = (quizdeckid) => {
    props.history.push("/contentdetails/" + quizdeckid);
  };
  return (
    <React.Fragment>
      {/* <BasicHeader 
            {...props}
        /> */}

      <div class="col-lg-6 col-md-12 quiz_details">
        <div class="top-performer">
          <div>
            <div
              id={"showReviews" + props.content.id}
              class="modal fade"
              role="dialog"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title">{t("reviews")}</h4>
                    <button type="button" class="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="reviews-listing">
                      {props.content.reviewsText.length > 0
                        ? props.content.reviewsText.map((review) => (
                          <div class="review-list">
                            <div class="user-img">
                              <img src={PROFILE_PIC + review.profile_pic} />
                            </div>
                            <div>
                              <div>{review.name}</div>
                              <div class="stars">
                                {review.rating > 0
                                  ? (function star() {
                                    let stars = [];
                                    for (
                                      let i = 1;
                                      i <= review.rating;
                                      i++
                                    ) {
                                      stars.push(
                                        <span
                                          onClick={() => setRatingStars(i)}
                                          class="icon-star active"
                                        ></span>
                                      );
                                    }
                                    return stars;
                                  })()
                                  : null}
                              </div>
                              <div class="comment">{review.review}</div>
                            </div>
                          </div>
                        ))
                        : "No Ratings Available"}
                    </div>
                  </div>
                  <div class="modal-footer">
                    <a
                      href="javascript:void(0)"
                      class="btn-background blue-bg-btn"
                      data-dismiss="modal"
                    >
                      {t("close")}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="border box_quize">
              <div class="head_pro">
                <div class="profile_pick">
                  <img
                    src={PROFILE_PIC + props.content.user_profile_pic}
                    width="100%"
                  />
                </div>
                <div class="create_by">
                  <h6 style={{ color: "#132D5B" }}>
                    {tHydrate(props.content.user_name)}
                  </h6>
                </div>
                <div className="icons">
                  {props.content.amount > 0 ? (
                    <span className="paid-text">paid</span>
                  ) : (
                    <span className="paid-text">free</span>
                  )}
                  {props.content.is_official_quiz == 1 ? (
                    <span className="icon icon-verified"></span>
                  ) : null}

                  {props.content.group_id ? (
                    <div class="d-inline-block contentLockBox">
                      <span class="contentGroupLock ml-5px closedLock">
                        <span
                          class={
                            props.content.open_close
                              ? "icon icon-unlock"
                              : "icon icon-lock"
                          }
                        ></span>
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div class="review-section">
                <div class="row align-items-center">
                  {props.content.languages.length > 0
                    ? (function language() {
                      let languages = [];
                      //console.log('adsfadsf')
                      languages = props.content.languages.map(
                        (language, index) => {
                          switch (language.name) {
                            case "English":
                              return (
                                <img
                                  key={index.toString()}
                                  src={`${awsImageUrl}/uploads/languages/usa.png`}
                                />
                              );
                              break;
                            case "Japanese":
                              return (
                                <img
                                  key={index.toString()}
                                  src={`${awsImageUrl}/uploads/languages/japan.png`}
                                />
                              );
                              break;
                            case "Chinese":
                              return (
                                <img
                                  key={index.toString()}
                                  src={`${awsImageUrl}/uploads/languages/china.png`}
                                />
                              );
                              break;
                            case "Hindi":
                              return (
                                <img
                                  key={index.toString()}
                                  src={`${awsImageUrl}/uploads/languages/india.png`}
                                />
                              );
                              break;
                          }
                          //languages.push(t(language.name))
                        }
                      );
                      return (
                        <div className="col-6 language-img">
                          {" "}
                          {languages}{" "}
                        </div>
                      );
                    })()
                    : null}

                  {props.content.types.length > 0
                    ? (function contentType() {
                      let types = [];
                      types = props.content.types.map((typeo, index) => {
                        switch (typeo.trim()) {
                          case "Text":
                            //console.log('adding')
                            return <span class="icon icon-text"></span>;
                            break;
                          case "Picture":
                            return (
                              <span className="icon icon-images1"></span>
                            );
                            break;
                          case "Audio":
                            return <span className="icon icon-audio1"></span>;
                            break;
                          case "Video":
                            return (
                              <span className="icon icon-youtube"></span>
                            );
                            break;
                        }
                      });

                      switch (props.content.complexity_id) {
                        case "1":
                          types.push(
                            <span className="icon icon-expert"></span>
                          );
                          break;
                        case "2":
                          types.push(
                            <span className="icon icon-intermediate"></span>
                          );
                          break;
                        case "3":
                          types.push(
                            <span className="icon icon-beginner"></span>
                          );
                          break;
                        case "4":
                          types.push(
                            <span className="icon icon-number1"></span>
                          );
                          break;
                        case "5":
                          types.push(
                            <span className="icon icon-number1"></span>
                          );
                          break;
                        case "6":
                          types.push(
                            <span className="icon icon-number2"></span>
                          );
                          break;
                        case "7":
                          types.push(
                            <span className="icon icon-number2"></span>
                          );
                          break;
                        case "8":
                          types.push(
                            <span className="icon icon-number3"></span>
                          );
                          break;
                        case "9":
                          types.push(
                            <span className="icon icon-number4"></span>
                          );
                          break;
                        case "10":
                          types.push(
                            <span className="icon icon-number5"></span>
                          );
                          break;
                        case "11":
                          types.push(
                            <span className="icon icon-number6"></span>
                          );
                          break;
                        case "12":
                          types.push(
                            <span className="icon icon-number7"></span>
                          );
                          break;
                        case "13":
                          types.push(
                            <span className="icon icon-number8"></span>
                          );
                          break;
                        case "14":
                          types.push(
                            <span className="icon icon-number9"></span>
                          );
                          break;
                        case "15":
                          types.push(
                            <span className="icon icon-number1"></span>
                          );
                          types.push(
                            <span className="icon icon-number0"></span>
                          );
                          break;
                        case "16":
                          types.push(
                            <IconPrimarySchool1stGrade />
                          );
                          break;
                        case "17":
                          types.push(
                            <IconPrimarySchool2ndGrade />
                          );
                          break;
                        case "18":
                          types.push(
                            <IconPrimarySchool3rdGrade />
                          );
                          break;
                        case "19":
                          types.push(
                            <IconPrimarySchool4thGrade />
                          );
                          break;
                        case "20":
                          types.push(
                            <IconPrimarySchool5thGrade />
                          );
                          break;
                        case "21":
                          types.push(
                            <IconPrimarySchool6thGrade />
                          );
                          break;
                        case "22":
                          types.push(
                            <IconLowerSecondarySchool1stGrade />
                          );
                          break;
                        case "23":
                          types.push(
                            <IconLowerSecondarySchool2ndGrade />
                          );
                          break;
                        case "24":
                          types.push(
                            <IconLowerSecondarySchool3rdGrade />
                          );
                          break;
                        case "25":
                          types.push(
                            <IconUpperSecondarySchool1stGrade />
                          );
                          break;
                        case "26":
                          types.push(
                            <IconUpperSecondarySchool2ndGrade />
                          );
                          break;
                        case "27":
                          types.push(
                            <IconUpperSecondarySchool3rdGrade />
                          );
                          break;
                      }

                      //console.log('types.length',types.length)
                      //types.push(<span className="icon icon-intermediate"></span>)
                      //types.push(<span className="icon icon-number8"></span>)
                      return (
                        <div className="col-6 user-content" style={{
                          textAlign: 'right'
                        }}> {types} </div>
                      );
                    })()
                    : null}

                  {Math.round(props.content.rating) > 0
                    ? (function star() {
                      let stars = [];

                      //
                      let currentRating = Math.round(props.content.rating);

                      for (let i = 0; i < currentRating; i++) {
                        stars.push(<span class="icon icon-star"></span>);
                      }
                      for (let j = currentRating; j < 5; j++) {
                        stars.push(
                          <span class="icon icon-star-border"></span>
                        );
                      }
                      stars.push(
                        <small>
                          <a
                            data-toggle="modal"
                            data-target={"#showReviews" + props.content.id}
                            href="javascript:void(0)"
                          >
                            {renderAppetizerNumber(props.content.reviews)}
                          </a>
                        </small>
                      );
                      return (
                        <div className="col-6 review-user">{stars}</div>
                      );
                    })()
                    : null}

                  {/* </div> */}
                  <div class="col-6 questions-section">
                    <div class="d-flex content-right" style={{ justifyContent: 'right' }}>
                      <span class=" icon icon-question2"></span>
                      <b class="count">{renderAppetizerNumber(props.content.questioncount)}</b>
                      <span class=" icon icon-download5"></span>
                      <b>
                        {renderAppetizerNumber(props.content.quiz_taken
                          ? props.content.quiz_taken
                          : 0)}{" "}
                      </b>
                    </div>
                  </div>
                </div>
              </div>

              <div class="">
                <div class="row">

                </div>
              </div>

              <div
                className="pt-2 heading-text"
                style={{
                  color: APPETIZER_COMMON_COLOR,
                  fontSize: '18px',
                  fontWeight: '600'
                }}
              >{striptags(props.content.name)}</div>

              <h7 className="pt-2" style={{ color: APPETIZER_COMMON_COLOR }}>
                ({t("content_id")}: {renderAppetizerNumber(props.content.id)})
              </h7>

              <div class="user-content-info" style={{
                color: APPETIZER_COMMON_COLOR,
                fontSize: '13px',
                fontWeight: '600',
                fontFamily: 'Arial'
              }}>
                {_.map(_.split(stripHtmlWithParagraphs(props.content.description ?? ''), '\n'), (str) => <div>{str}</div>)}
              </div>

              <div
                class="quize_listing"
                onClick={() => goToContentDetailsPage(props.content.id)}
              >
                <button class="continue blue-bg-btn">
                  {t("contentDetails")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default ContentComponent;
