/* eslint-disable */

import React, { useContext, useEffect, useState } from "react";
//import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import { useCookies } from "react-cookie";
import { getDeviceUUID, removeUserSession } from "../Utils/Common";
// import "../images/favicon.ico"
import axios from "axios";
import qs from "qs";
import dashlogo from "../images/dash-logo.png";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/counter.css";
import "../less/icon-font.css";
import "../less/login.css";
import "../less/menu.css";
import "../less/releway-font.css";

import { useTranslation } from "react-i18next";

import { Context } from "../Reducers/Store";

let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";

export function BasicHeader(props) {
  console.log(props);
  const [state, dispatch] = useContext(Context);
  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  // const [connectSocket, getNotificationCount, getInvitaionCount] = useContext(WebSocketContext)

  const { t } = useTranslation();
  //  var MyNav = withImportantStyle('nav');
  //    console.log("header props", props)
  const [activeBar, setActiveBar] = useState("home");

  const user = cookies["user"]; //getUser();
  //console.log(user)
  const user_type_id = user.user_type_id;

  //console.log("user_type_id", user_type_id)
  let globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  if (typeof globalLang == "string") {
    globalLang = JSON.parse(globalLang);
  }
  //console.log('getUserGlobalLanguage()', getUserGlobalLanguage(), typeof (getUserGlobalLanguage()))
  //console.log('globalLang', typeof (globalLang))
  let globalLangCode;
  if (!globalLang) {
    globalLangCode = i18next.language;
  } else {
    globalLangCode = globalLang.lang_code;
  }
  //console.log('Basic Header globalLangCode', globalLangCode)

  const token = cookies["access_token"]; //getToken()

  useEffect(() => {
    setActiveBar(props.activeBar);
  }, []);

  useEffect(() => {
    setMenufromState();
  }, [state]);

  const setMenufromState = () => {
    //anchor_id_elem.className = "nav-link active"
    if (state) {
      let anchor_id = state.CURRENT_MENU_ID; //getActiveAnchor()
      console.log("state.CURRENT_MENU_ID", state.CURRENT_MENU_ID);
      var anchor_id_elem = document.getElementById(anchor_id);
      if (anchor_id_elem) {
        inActiveAll();
        console.log("anchor_id==", anchor_id);
        //console.log('aaaa',document.getElementById("id_played_content").class)

        const skip_nav_link_cls = ["id_contents_page", "id_attributes_page"];
        if (!skip_nav_link_cls.includes(anchor_id))
          anchor_id_elem.classList.add("nav-link");
        anchor_id_elem.classList.add("active");
        if (anchor_id !== "id_home") {
          var anchor_id_home = document.getElementById("id_home");
          if (anchor_id_home) {
            anchor_id_home.class = "nav-link";
          }
        }
      }
    }
  };

  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState({});

  useEffect(() => {
    console.log("oolanguages", languages);
  }, [languages]);
  useEffect(() => {
    console.log("----globalLangCode", globalLangCode);
    updateMetaTags();
    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        console.log("---languages", response.data.data);

        response.data.data.forEach((languageObj) => {
          console.log("i18next.language", i18next.language);
          if (languageObj.lang_code === i18next.language) {
            console.log("here");
            console.log("languageObj");
            //setUserGlobalLanguage(token, languageObj)
            //setUserLoginLanguage(languageObj)
            setLanguage(languageObj);
          }
        });
        setLanguages(response.data.data);

        console.log("state.CURRENT_MENU_ID", state.CURRENT_MENU_ID);
        // setTimeout(() => getNotificationCount(),2000)
      })
      .catch((error) => {});
  }, []);

  const goToGenrePage = () => {
    props.history.push("/genrelist");
  };

  const goRepeatContentPage = () => {
    props.history.push("/repeatcontentpage");
  };

  const gotoNotificationsPage = () => {
    props.history.push("/notifications");
  };

  const gotoEmailPage = () => {
    props.history.push("/invitations");
  };

  const goToHomePage = () => {
    props.history.push("/dashboard");
  };
  const logoutSession = () => {
    axios({
      method: "get",
      url: "/api/user/logout/" + getDeviceUUID(),
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log("logout response", response);
      })
      .catch((error) => {
        //   removeUserSession();
      });
    removeAllCookies();
    removeUserSession();
    props.history.push("/login");
  };
  const removeAllCookies = () => {
    removeCookie("memorizeitallCookie");
    removeCookie("access_token");
    removeCookie("user");
    removeCookie("expiry_time");
    removeCookie("global_language");
    removeCookie("local_language");
  };
  const inActiveAll = () => {
    console.log("here");
    var anchor_id_home = document.getElementById("id_home");
    if (anchor_id_home) {
      anchor_id_home.classList.remove("active");
      anchor_id_home.class = "nav-link";
    }
    var anchor_id_played_content = document.getElementById("id_played_content");
    if (anchor_id_played_content) {
      anchor_id_played_content.classList.remove("active");
      anchor_id_played_content.class = "nav-link";
    }
    var anchor_id_my_contents = document.getElementById("id_my_contents");
    if (anchor_id_my_contents) {
      anchor_id_my_contents.classList.remove("active");
      anchor_id_my_contents.class = "nav-link";
    }
    var anchor_id_genre_page = document.getElementById("id_genre_page");
    if (anchor_id_genre_page) {
      anchor_id_genre_page.classList.remove("active");
      anchor_id_genre_page.class = "nav-link";
    }
    var anchor_id_attributes_page =
      document.getElementById("id_attributes_page");
    if (anchor_id_attributes_page) {
      anchor_id_attributes_page.classList.remove("active");
      anchor_id_attributes_page.class = "nav-link";
    }
    var anchor_id_my_groups = document.getElementById("id_my_groups");
    if (anchor_id_my_groups) {
      anchor_id_my_groups.classList.remove("active");
      anchor_id_my_groups.class = "nav-link";
    }
    var anchor_id_contents_page = document.getElementById("id_contents_page");
    if (anchor_id_contents_page) {
      anchor_id_contents_page.classList.remove("active");
      anchor_id_contents_page.class = "nav-link";
    }
    var anchor_id_invite_page = document.getElementById("id_invite_page");
    if (anchor_id_invite_page) {
      anchor_id_invite_page.classList.remove("active");
      anchor_id_invite_page.class = "nav-link";
    }
    var anchor_id_search_page = document.getElementById("id_search_page");
    if (anchor_id_search_page) {
      anchor_id_search_page.classList.remove("active");
      anchor_id_search_page.class = "nav-link";
    }
    var anchor_id_profile = document.getElementById("id_profile");
    if (anchor_id_profile) {
      anchor_id_profile.classList.remove("active");
      anchor_id_profile.class = "nav-link";
    }
    var anchor_id_repeat_contents_page = document.getElementById(
      "id_repeat_contents_page"
    );
    if (anchor_id_repeat_contents_page) {
      anchor_id_repeat_contents_page.classList.remove("active");
      anchor_id_repeat_contents_page.class = "nav-link";
    }
  };

  const gotoSearchPage = () => {
    props.history.push("/search");
  };

  const updateMetaTags = () => {
    // Update title
    console.log("meta_title", t("meta_description"));
    document.title = t("meta_title");

    // Update meta tags
    const metaTags = [
      { name: "description", content: t("meta_description") },
      { name: "keywords", content: t("meta_keywords") },
      { property: "og:title", content: t("meta_ogTitle") },
      { property: "og:description", content: t("meta_ogDescription") },
    ];

    metaTags.forEach((meta) => {
      let element = document.querySelector(
        `meta[${meta.name ? "name" : "property"}="${
          meta.name || meta.property
        }"]`
      );
      if (!element) {
        element = document.createElement("meta");
        if (meta.name) {
          element.setAttribute("name", meta.name);
        } else {
          element.setAttribute("property", meta.property);
        }
        document.head.appendChild(element);
      }
      element.setAttribute("content", meta.content);
    });
  };

  const handelLanguageChange = (language) => {
    console.log("language-metadata");
    //setUserGlobalLanguage(token, language)
    //setUserLoginLanguage(language)
    setLanguage(language);
    updateMetaTags();

    window.location.reload();
  };
  const gotoMyContents = () => {
    props.history.push("/createcontentpage");
  };
  const goToMyFolders = () => {
    props.history.push("/myfolders");
  };
  const showRestrictedMessage = () => {
    console.log("showRestrictedMessage");
    document.getElementById("id-restrictedModal").click();
  };
  const gotoMyPrivateContents = () => {
    console.log("going to my private content");
    props.history.push("/mycontentsprivate");
  };
  const gotoMyGroupContents = () => {
    props.history.push("/mycontentsgroup");
  };
  const gotoMyRepeatContents = () => {
    props.history.push("/repeatcontentpage");
  };
  const gotoAttributesPage = () => {
    axios({
      method: "post",
      url: "/api/quiz_deck/createcheck",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: "en",
      },
    })
      .then((response) => {
        console.log("can create content success", response);
        props.history.push("/selectattributes");
        //setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
        console.log("can create content error", error.response);
        document.getElementById("freeCheckModal").click();
      });
  };
  const goToEditContent = () => {
    props.history.push("/mycontentsbuttons");
  };
  const goToMyGroups = () => {
    props.history.push("/mygroups");
  };
  const goToUpdateProfile = () => {
    props.history.push("/updateprofile");
  };
  const gotoMyPlayedContentsPage = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (user_type_id == 8) {
      props.history.push("/selectattributes");
    } else {
      axios({
        method: "post",
        url: "/api/my/played/quiz/recentlyplayed",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-access-token": "Memo " + token,
          locale: "en",
        },
        data: qs.stringify({
          page: "1",
        }),
      })
        .then((response) => {
          let quizzesList = response.data.data;
          console.log("quizzes", quizzesList);

          props.history.push(
            "/contentdetails/" + response.data.data.quiz_deck_id
          );
          //setUserSession(token, response.data.user);
        })
        .catch((error) => {
          // removeUserSession();
        });
    }
  };

  const goToMyBecomeAPremiumUser = () => {
    props.history.push("/managesubscriptions/" + 3); //3 for premium user
  };

  return (
    <React.Fragment>
      {/* ends here */}

      <section class="navtop animated fadeInDown">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <div class="social-icons">
                <a
                  href="https://www.facebook.com/memorizeitall"
                  target="_blank"
                  class="facebook"
                >
                  <span class="icon-facebook"></span>
                </a>
                <a
                  href="https://twitter.com/memorizeitall"
                  target="_blank"
                  class="twitter"
                >
                  <span class="icon-twitter"></span>
                </a>
                <a
                  href="https://linkedin.com/company/memorizeitall"
                  target="_blank"
                  class="linkedin"
                >
                  <span class="icon-linkedin"></span>
                </a>
                {/* <a href="javascript:void(0)" class="instagram"><span class="icon-instagram"></span></a> */}
              </div>
            </div>
            <div class="col-6 right-drop">
              <div class="language mob_disp">
                {/* <div class="dropdown">
                            <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                           
                                {language ?
                                    (
                                        <React.Fragment>

                                            <img  height='30%'  width='30%' src={(COUNTRY_FLAG + language.flag)} alt="Img"></img> 
                                            <span class="marR02">{language.name}</span>
                                        </React.Fragment>)
                                : null}
                            </a>
                            <Languages languages={languages} onSelectLanguage={handelLanguageChange}/>
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <nav class="navbar navbar-expand-lg navbar-dark memorize-navbar animated fadeInDown">
        <div class="container listItemContainer">
          <a class="navbar-brand" href="" onClick={gotoMyPlayedContentsPage}>
            <img src={dashlogo} />
            {/* <p>{t('app_name')}</p> */}
            <p>
              Memorize<span>it</span>all
            </p>
          </a>

          <div class="language">
            <div class="dropdown">
              <a
                href=""
                onClick={(e) => e.preventDefault()}
                class="dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                {language ? (
                  <React.Fragment>
                    <img
                      height="30%"
                      width="30%"
                      src={COUNTRY_FLAG + language.flag}
                      alt="Img"
                    ></img>
                    <span class="marR02">{language.name}</span>
                  </React.Fragment>
                ) : null}
              </a>
              <Languages
                languages={languages}
                onSelectLanguage={handelLanguageChange}
              />
            </div>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="form-inline">
            <div
              class="collapse navbar-collapse mobile-menu"
              id="collapsibleNavbar"
            >
              <ul class="navbar-nav">
                <li class="nav-item" onClick={goToGenrePage}>
                  <a
                    id="id_genre_page"
                    className="nav-link"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("genere")}
                  >
                    <span class="icon-m-home navLinkItem"></span>
                    <h6>{t("ad_genre")}</h6>
                  </a>
                </li>
                <li class="nav-item" onClick={gotoMyPlayedContentsPage}>
                  <a
                    id="id_played_content"
                    className="nav-link"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("start_memorizing")}
                  >
                    <span class="icon-m-bulb navLinkItem"></span>
                    <h6>{t("start_memorizing")}</h6>
                  </a>
                </li>
                {/* <li class="nav-item"
                                                onClick={gotoMyContents}
                                            >
                                                <a id="id_my_contents" className="nav-link" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title={t('myquiz')}>
                                                    <span class="icon-m-content"></span>
                                                    <h6>My contents</h6>
                                                </a>
                                            </li> */}
                <div class="login-and-menu">
                  <ul>
                    <li class="dropdown">
                      <a
                        href="javascript:void(0)"
                        id="id_contents_page"
                        class="circle-link logged-in-user-btn dropdown-toggle contentBtn folderGroup"
                        data-toggle="dropdown"
                        aria-expanded="true"
                        title={t("contents")}
                      >
                        <span class="icon-images headerContentIcon navLinkItem"></span>
                        <h6 class="pl-20px">{t("contents")}</h6>
                      </a>
                      <div class="dropdown-menu dropdown-menu-left arrow-top left blue">
                        <ul>
                          <li onClick={gotoMyContents}>
                            <a href="javascript:void(0)">
                              <span class="icon icon-images"></span>
                              {t("quizes")}
                            </a>
                          </li>
                          {user_type_id == 2 ? (
                            <li onClick={showRestrictedMessage}>
                              <a
                                href="javascript:void(0)"
                                data-toggle="modal"
                                data-backdrop="static"
                                data-target="#restrictedModal"
                                id={"id-restrictedModal"}
                              >
                                <span class="icon icon-folder1"></span>
                                {t("my_folders")}
                                <span class="icon-circle-minus1 restrictIcon"></span>
                              </a>
                            </li>
                          ) : (
                            <li onClick={goToMyFolders}>
                              <a href="javascript:void(0)">
                                <span class="icon icon-folder1"></span>
                                {t("my_folders")}
                              </a>
                            </li>
                          )}

                          <li onClick={gotoMyPrivateContents}>
                            <a href="javascript:void(0)">
                              <span class="icon icon-lock"></span>
                              {t("privatequiz")}
                            </a>
                          </li>
                          {user_type_id == 2 ? (
                            <li onClick={showRestrictedMessage}>
                              <a
                                href="javascript:void(0)"
                                data-toggle="modal"
                                data-backdrop="static"
                                data-target="#restrictedModal"
                                id={"id-restrictedModal"}
                              >
                                <span class="icon icon-m-group"></span>
                                {t("group_quiz")}
                                <span class="icon-circle-minus1 restrictIcon"></span>
                              </a>
                            </li>
                          ) : (
                            <li onClick={gotoMyGroupContents}>
                              <a href="javascript:void(0)">
                                <span class="icon icon-m-group"></span>
                                {t("group_quiz")}
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                <li class="nav-item" onClick={goRepeatContentPage}>
                  <a
                    id="id_repeat_contents_page"
                    className="nav-link"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("repeat")}
                  >
                    <span class="icon-repeating1 navLinkItem"></span>
                    <h6>{t("repeat")}</h6>
                  </a>
                </li>
                {1 == 0 ? (
                  <li class="nav-item" onClick={goToGenrePage}>
                    <a
                      id="id_genre_page"
                      className="nav-link"
                      href="javascript:void(0)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={t("genere")}
                    >
                      <span class="icon-m-genre navLinkItem"></span>
                      <h6>{t("ad_genre")}</h6>
                    </a>
                  </li>
                ) : null}
                <li class="nav-item" onClick={gotoEmailPage}>
                  <a
                    id="id_invite_page"
                    className="nav-link inboxLink p-relative"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("invitation")}
                  >
                    <span class="icon-inbox1 navLinkItem"></span>
                    {state.CURRENT_INVITATION_COUNT > 0 ? (
                      <span class="notifyNumber">
                        {state.CURRENT_INVITATION_COUNT}
                      </span>
                    ) : null}
                    <h6>{t("invitation")}</h6>
                  </a>
                </li>
                <li class="nav-item" onClick={gotoSearchPage}>
                  <a
                    id="id_search_page"
                    className="nav-link"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("search")}
                  >
                    <span class="icon-m-search navLinkItem"></span>
                    <h6>{t("how_search")}</h6>
                  </a>
                </li>
                {/* <li class="nav-item"
                                                onClick={gotoAttributesPage}
                                            >
                                                <a id="id_attributes_page" className="nav-link" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title={t('createquiz')}>
                                                    <span class="icon-m-create"></span>
                                                    <h6>Create contents</h6>
                                                </a>
                                            </li> */}
                {/* <li className="nav-item" onClick={gotoSearchPage}>
                                                <div class="serach-icon">
                                                    <a href="javascript:void(0)" class="top-search-icon"><span class="icon-search"></span></a>
                                                </div>
                                            </li> */}
                {/* <li class="nav-item" onClick={gotoSearchPage}>
                                                <a id="id_search_page" className="nav-link top-search-icon icon-search" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title={t('search')}>
                                                    <span class="icon-m-search"></span>
                                                    <h6>Search</h6>
                                                </a>
                                            </li> */}
                <div class="login-and-menu">
                  <ul>
                    <li class="dropdown ">
                      <a
                        href="javascript:void(0)"
                        id="id_attributes_page"
                        class="circle-link logged-in-user-btn dropdown-toggle contentBtn"
                        data-toggle="dropdown"
                        aria-expanded="true"
                        title={t("modify_contents")}
                      >
                        <span class="icon-m-create navLinkItem createContentIcon"></span>
                        <h6 class="pl-20px">{t("modify_contents")}</h6>
                      </a>
                      <div class="dropdown-menu dropdown-menu-left arrow-top left blue">
                        <ul>
                          {/* <li class="usernameItem">
                                                                    <a href="javascript:void(0)">
                                                                        <span class="contentUser">
                                                                        <span class="icon-user2"></span>
                                                                        </span>
                                                                <span class="user">{user.name}</span>
                                                                
                                                                    </a>
                                                                </li> */}
                          <li onClick={gotoAttributesPage}>
                            <a href="javascript:void(0)">
                              <span class="icon icon-m-create"></span>
                              {t("createquiz")}
                            </a>
                          </li>
                          <li onClick={goToEditContent}>
                            <a href="javascript:void(0)">
                              <span class="icon icon-m-edit-content"></span>
                              {t("edit_contents")}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                {user_type_id == 2 ? (
                  <li class="nav-item" onClick={showRestrictedMessage}>
                    <a
                      className="nav-link"
                      href="javascript:void(0)"
                      data-placement="top"
                      title={t("group")}
                      data-toggle="modal"
                      data-backdrop="static"
                      data-target="#restrictedModal"
                      id="id_my_groups"
                    >
                      <div class="position-relative d-inline-block">
                        <span class="icon-m-group navLinkItem"></span>
                        <span class="icon-circle-minus1 restrictIcon"></span>
                      </div>

                      <h6>{t("group")}</h6>
                    </a>
                  </li>
                ) : (
                  <li class="nav-item" onClick={goToMyGroups}>
                    <a
                      id="id_my_groups"
                      className="nav-link"
                      href="javascript:void(0)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={t("group")}
                    >
                      <div class="position-relative d-inline-block">
                        <span class="icon-m-group navLinkItem"></span>
                      </div>

                      <h6>{t("group")}</h6>
                    </a>
                  </li>
                )}
                <li class="nav-item" onClick={goToHomePage}>
                  {/* <a class="nav-link active" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title="Home"><span class="icon-m-home"></span></a> */}
                  <a
                    class="nav-link"
                    id="id_profile"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("Profile")}
                  >
                    <span class="icon icon-user headerUserIcon navLinkItem"></span>
                    <h6>{t("Profile")}</h6>
                  </a>
                </li>

                <li class="nav-item" onClick={logoutSession}>
                  {/* <a class="nav-link active" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title="Home"><span class="icon-m-home"></span></a> */}
                  <a
                    class="nav-link"
                    id="id_profile"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("sign_out")}
                  >
                    <span class="icon icon-logout headerUserIcon navLinkItem"></span>
                    <h6>{t("sign_out")}</h6>
                  </a>
                </li>
              </ul>
            </div>
            {/* <div class="login-and-menu">
                                        <ul>
                                            <li style={{ display: "none" }}>
                                                <a href="javascript:void(0)" class="circle-link login-btn"><span class="icon-login icon"></span><span class="login-txt">Login</span></a>
                                            </li>
                                            <li class="dropdown ">
                                                <a href="javascript:void(0)" class="circle-link logged-in-user-btn dropdown-toggle" data-toggle="dropdown" aria-expanded="true"><span class="icon-user"></span></a>
                                                <div class="dropdown-menu dropdown-menu-right arrow-top right blue">
                                                    <div class="user-name">
                                                        <div class="user-icon"><span class="icon-user"></span></div>
                                                        <div>{user_name}</div>
                                                    </div>
                                                    <ul>
                                                        <li onClick={gotoNotificationsPage}><a href="javascript:void(0)"><span class="icon icon-m-notifications"></span>{t("notifications")}</a></li>
                                                        <li onClick={gotoEmailPage}><a href="javascript:void(0)"><span class="icon icon-m-mail"></span>{t("invitation")}</a></li>
                                                        <li onClick={goToHomePage}><a href="javascript:void(0)"><span class="icon icon-m-profile"></span>{t("Profile")}</a></li>
                                                        <li onClick={goToUpdateProfile}><a href="javascript:void(0)"><span class="icon icon-m-profile"></span>{t('editProfile')}</a></li>
                                                        <li onClick={logoutSession}><a href="javascript:void(0)"><span class="icon icon-logout"></span>{t('logout')}</a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            
                                            <li style={{ position: "relative" }}>
                                                <div class="serach-icon"
                                                    onClick={gotoSearchPage}
                                                >
                                                    <a href="javascript:void(0)" class="top-search-icon"><span class="icon-search"></span></a>
                                                </div>
                                            </li>

                                        </ul>
                                    </div> */}
          </div>
        </div>
      </nav>

      <p
        class="details-play-quiz"
        id="freeCheckModal"
        data-toggle="modal"
        data-target="#freeCheck"
        style={{ display: "none" }}
      >
        Open Modal
      </p>

      <div
        class="modal fade add-rate-review-info"
        id="freeCheck"
        tabindex="-1"
        role="dialog"
        aria-labelledby="freeCheck"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {t("access_rights")}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="add-rate-review-info">
                <p>
                  <strong>{t("free_user_cannot_create_5_contents")}</strong>
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <div class="save-cancel-btn">
                <a
                  data-dismiss="modal"
                  onClick={goToMyBecomeAPremiumUser}
                  href="javascript:void(0)"
                  class="btn-border blue-bg-btn"
                >
                  {t("become_a_premium_member")}
                </a>
                {/* 
                                <a
                                    data-dismiss="modal"
                                    // onClick={skipReview} 
                                    href="javascript:void(0)" class="btn-background blue-bg-btn">
                                    {t("skip")}
                                </a>
                                */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="restrictedModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Access Restricted
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>{t("upgrade_your_membership")}</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function Languages(props) {
  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  console.log("props.languages", props.languages);
  const setCookieLoginLanguage = (loginLanguage) => {
    let exp = 30 * 24 * 60 * 60;
    console.log("in setCookieLoginLanguage", exp);
    var expires = new Date();
    expires.setTime(expires.getTime() + exp * 1000);
    setCookie("login_language", JSON.stringify(loginLanguage), {
      path: "/",
      expires,
    });
  };
  const setCookieGlobalLanguage = (globalLanguage) => {
    let exp = 30 * 24 * 60 * 60;
    console.log("in setCookieGlobalLanguage", exp);
    var expires = new Date();
    expires.setTime(expires.getTime() + exp * 1000);
    setCookie("global_language", JSON.stringify(globalLanguage), {
      path: "/",
      expires,
    });
  };
  function setLanguage(language) {
    setCookieLoginLanguage(language);
    setCookieGlobalLanguage(language);
    //setUserGlobalLanguage(token, language)
    //setUserLoginLanguage(language)

    i18next.changeLanguage(language.lang_code).then((t) => {
      console.log(t("app_name"));
    });
    console.log("our i18", i18next);

    props.onSelectLanguage(language);
  }
  return (
    <ul className="dropdown-menu dropdown-menu-right arrow_top">
      {/*
            <li><a href="javascript:void(0)"><span className="sprite-flags china"></span> <span>English</span></a></li>
            <li><a href="javascript:void(0)"><span className="sprite-flags Italy"></span> <span>Italian</span></a></li>
            */}
      {props.languages && props.languages.length > 0
        ? props.languages.map((language, i) => {
            return (
              <li key={i}>
                <a
                  onClick={() => setLanguage(language)}
                  href="javascript:void(0)"
                >
                  <img
                    height="30%"
                    width="30%"
                    src={COUNTRY_FLAG + language.flag}
                    alt="Img"
                  />
                  &nbsp;<span> {language.name}</span>
                </a>
              </li>
            );
          })
        : null}
    </ul>
  );
}

export default BasicHeader;
