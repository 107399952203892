import React from "react";
import LowerSecondarySchool1stGrade from "../assets/icons/lower-secondary-school-1st-grade.png";
import LowerSecondarySchool2ndGrade from "../assets/icons/lower-secondary-school-2nd-grade.png";
import LowerSecondarySchool3rdGrade from "../assets/icons/lower-secondary-school-3rd-grade.png";
import PrimarySchool1stGrade from "../assets/icons/primary-school-1st-grade.png";
import PrimarySchool2ndGrade from "../assets/icons/primary-school-2nd-grade.png";
import PrimarySchool3rdGrade from "../assets/icons/primary-school-3rd-grade.png";
import PrimarySchool4thGrade from "../assets/icons/primary-school-4th-grade.png";
import PrimarySchool5thGrade from "../assets/icons/primary-school-5th-grade.png";
import PrimarySchool6thGrade from "../assets/icons/primary-school-6th-grade.png";
import UpperSecondarySchool1stGrade from "../assets/icons/upper-secondary-school-1st-grade.png";
import UpperSecondarySchool2ndGrade from "../assets/icons/upper-secondary-school-2nd-grade.png";
import UpperSecondarySchool3rdGrade from "../assets/icons/upper-secondary-school-3rd-grade.png";

const commonIconStyle = { width: "24px", height: "24px", marginBottom: "12px" };

export const IconPrimarySchool1stGrade = () => {
  return <img src={PrimarySchool1stGrade} alt="icon" style={commonIconStyle} />;
};
export const IconPrimarySchool2ndGrade = () => {
  return <img src={PrimarySchool2ndGrade} alt="icon" style={commonIconStyle} />;
};
export const IconPrimarySchool3rdGrade = () => {
  return <img src={PrimarySchool3rdGrade} alt="icon" style={commonIconStyle} />;
};
export const IconPrimarySchool4thGrade = () => {
  return <img src={PrimarySchool4thGrade} alt="icon" style={commonIconStyle} />;
};
export const IconPrimarySchool5thGrade = () => {
  return <img src={PrimarySchool5thGrade} alt="icon" style={commonIconStyle} />;
};
export const IconPrimarySchool6thGrade = () => {
  return <img src={PrimarySchool6thGrade} alt="icon" style={commonIconStyle} />;
};
export const IconLowerSecondarySchool1stGrade = () => {
  return (
    <img
      src={LowerSecondarySchool1stGrade}
      alt="icon"
      style={commonIconStyle}
    />
  );
};
export const IconLowerSecondarySchool2ndGrade = () => {
  return (
    <img
      src={LowerSecondarySchool2ndGrade}
      alt="icon"
      style={commonIconStyle}
    />
  );
};
export const IconLowerSecondarySchool3rdGrade = () => {
  return (
    <img
      src={LowerSecondarySchool3rdGrade}
      alt="icon"
      style={commonIconStyle}
    />
  );
};
export const IconUpperSecondarySchool1stGrade = () => {
  return (
    <img
      src={UpperSecondarySchool1stGrade}
      alt="icon"
      style={commonIconStyle}
    />
  );
};
export const IconUpperSecondarySchool2ndGrade = () => {
  return (
    <img
      src={UpperSecondarySchool2ndGrade}
      alt="icon"
      style={commonIconStyle}
    />
  );
};
export const IconUpperSecondarySchool3rdGrade = () => {
  return (
    <img
      src={UpperSecondarySchool3rdGrade}
      alt="icon"
      style={commonIconStyle}
    />
  );
};
